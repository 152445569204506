import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

interface HeaderProps {
  title: string;
  handleInstagramLogout?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, handleInstagramLogout = null }) => (
  <Root>
    <BrandIcon src="/images/relatable_circle.svg" alt="brand_logo" />

    <Title>{title}</Title>
    {handleInstagramLogout ? (
      <LogoutButton onClick={() => handleInstagramLogout()}>Logout</LogoutButton>
    ) : null}
  </Root>
);

const Root = styled.header`
  width: 100%;
  max-width: 400px;
  background: #fffffe;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100px;
  padding-top: 36px;
  padding-bottom: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const BrandIcon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 24px;
`;

const Title = styled.a`
  text-decoration: none;
  color: ${palette.gray[90]};
  position: absolute;
  left: 74px;
  font-weight: 700;
`;

const LogoutButton = styled.button`
  text-decoration: none;
  color: ${palette.gray[60]};
  width: auto;
  text-align: center;
  margin-right: 15px;
  font-size: 0.8rem;
  font-weight: 700;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;
