import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { VIDEO_FILE_TYPES, endsWithAny, normalizeState } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Loader } from "@relatable/ui/Loader";
import {
  ContentMediaDocument,
  type ContentMediaQuery,
  useMoveMediaUpMutation
} from "modules/approval/components/ContentApproval/Stepper/Step/generated";
import {
  type UseUploadMediaParams,
  useUploadMedia
} from "modules/approval/components/ContentApproval/Stepper/useUploadMedia";

import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { CotentItemCaption } from "../ContentItemCaption";
import { FilePicker } from "../FilePicker";
import { ContentItemHeader } from "./common/ContentItemHeader";
import { VideoDisplay } from "./common/VideoDisplay";

export const ContentDisplay: React.FC<{
  editable: boolean;
  active: boolean;
  index: number;
  progress?: number | null;
  isStory?: boolean;
  acceptedTypes: string[];
  media: ContentMediaQuery["medias"][0] | null;
  nextMedia: ContentMediaQuery["medias"][0] | null; // required for moving
  numComments: number;
  numSlides: number;
  uploading: boolean;
  uploadParams: UseUploadMediaParams;
}> = ({
  editable,
  active,
  index,
  progress,
  isStory,
  acceptedTypes,
  media,
  nextMedia,
  numComments,
  numSlides,
  uploading = false,
  uploadParams
}) => {
  const filePickerRef = useRef<{
    open(): void;
  }>(null);
  const snackbar = useSnackbar();
  const [loadingContent, setLoadingContent] = useState(true);
  const [movingContent, setMovingContent] = useState(false);

  const getStatus = () => {
    if (uploading) {
      return "Your content is being uploaded, please do not close the browser...";
    }
    if (movingContent) {
      return "Rearranging picture order";
    }
    if (loadingContent) {
      return "Your content is loading";
    }
    return "";
  };

  const [moveMediaUp, { loading: moveMediaUpLoading }] = useMoveMediaUpMutation({
    onCompleted: () => setMovingContent(false),
    awaitRefetchQueries: true,
    refetchQueries: [ContentMediaDocument]
  });

  const handleMoveMedia = async (direction: "left" | "right") => {
    if (!media) return;
    const movedMediaId = direction === "left" ? media.id : nextMedia?.id;
    if (!movedMediaId) return;
    setMovingContent(true);
    await moveMediaUp({ variables: { media_id: movedMediaId } });
  };

  const [uploadMedia, { loading: uploadMediaLoading }] = useUploadMedia();

  const handleFileUploadChange: (e: React.ChangeEvent<HTMLInputElement>) => void = async event => {
    try {
      event.persist();

      const filesArr = Array.from(event.target.files ?? []);
      if (filesArr.length !== 1) {
        snackbar.error("Only a single file should be selected");
        return;
      }

      uploadMedia(filesArr[0], uploadParams);
    } catch {
      snackbar.error(
        "Something went wrong 😔 When uploading an image. Try to convert it online to a different extension or try a different image."
      );
    }
  };

  const handleDownload = async () => {
    const urlWithoutSignature = media?.converted_filename || media?.filename;
    if (!urlWithoutSignature) return;
    const signedfileUrl = media?.url;
    if (!signedfileUrl) return;

    try {
      const blob = await fetch(signedfileUrl).then(x => x.blob());
      const url = URL.createObjectURL(blob);
      const fileName = urlWithoutSignature.split("/").pop() as string;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1000);
    } catch (err) {
      console.error(err);
      snackbar.error("Cannot download file. Try downloading it manually.");
    }
  };

  const loading = uploading || loadingContent || moveMediaUpLoading || movingContent;
  const mediaStatus = normalizeState(media?.state);

  const renderItem = () => {
    if (VIDEO_FILE_TYPES.find(type => (media?.filename ?? "").includes(type))) {
      if (!active) return null;
      return (
        <VideoDisplay
          key={media?.filename}
          isVideoSupported={endsWithAny({
            suffixes: ["mp4", "mov"],
            string: media?.converted_filename || media?.filename || ""
          })}
          url={media?.url ?? "#"}
          onLoad={() => setLoadingContent(false)}
          style={{ opacity: loading ? 0.01 : 1 }}
        />
      );
    }

    return (
      <PreviewImage
        onLoad={() => setLoadingContent(false)}
        src={media?.thumbnail ?? media?.url ?? "#"}
        style={{ opacity: loading ? 0.01 : 1 }}
      />
    );
  };

  return (
    <Root>
      <FilePicker
        ref={filePickerRef}
        invisible
        onFileUploadChange={handleFileUploadChange}
        acceptedFiles={acceptedTypes}
      />

      <ContentItemHeader
        index={index}
        numSlides={numSlides}
        numComments={numComments}
        mediaStatus={mediaStatus}
        onMoveMedia={handleMoveMedia}
        onDownload={handleDownload}
        editable={editable}
      />

      <ContentWrapper id={`content-wrapper-${media?.filename}`}>
        {loading ? (
          <NoImagePlaceholder>
            <Loader progress={progress} message={getStatus()} />
          </NoImagePlaceholder>
        ) : null}

        {renderItem()}

        {mediaStatus === CONTENT_APPROVAL_STATE.MANAGER_REJECTED && editable && media && (
          <Button
            onClick={() => filePickerRef.current?.open()}
            isLoading={uploadMediaLoading}
            disabled={uploadMediaLoading}
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              transform: "translateX(-50%)",
              background: palette.primary.red
            }}
          >
            Replace media
          </Button>
        )}
      </ContentWrapper>

      {isStory && media ? (
        <CaptionWrapper>
          <CotentItemCaption
            key={media?.id}
            captionId={media.caption?.id ?? 0}
            mediaId={media.id}
            text={media.caption?.text ?? ""}
            editable={editable}
          />
        </CaptionWrapper>
      ) : null}
    </Root>
  );
};

const MAX_FRAME_HEIGHT = 392;

const Root = styled.div`
  padding: 0 15px;
  position: relative;
`;

const NoImagePlaceholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  background: ${palette.gray.whiteOff};
`;

const ContentWrapper = styled.div`
  background-color: ${palette.gray.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${MAX_FRAME_HEIGHT}px;
  border: 1px solid ${palette.gray[20]};
  border-top: 0px;
  border-radius: 0 0 5px 5px;
  user-select: none;
`;

const CaptionWrapper = styled.div``;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
