import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { CountrySelect } from "@relatable/ui/CountrySelect";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useLocation, useNavigate } from "react-router-dom";

import { type UserByEncodedIdQueryResult, useGigaPayRegisterUserMutation } from "../generated";
import { useEncodedUser } from "../useEncodedUser";
import { useRedirector } from "../useRedirector";
import { Logo } from "./GigaPayLogo";

export const PaymentStep: FC = () => {
  const { user } = useEncodedUser();
  const { isLoaded } = useRedirector();

  if (!isLoaded || !user) return <Loader />;

  return <PaymentStepContent user={user} />;
};

const InputsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  gap: 20px;
`;
const PaymentStepContent: FC<{
  user: NonNullable<UserByEncodedIdQueryResult["data"]>["agreementDataByEncodedId"];
}> = ({ user }) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { pathname } = useLocation();

  const [gigaPayRegisterUser, { loading }] = useGigaPayRegisterUserMutation({
    onCompleted: () => navigate(pathname.replace("/payment", "/done")),
    onError: () =>
      snackbar.error("Something went wrong 😔, Please make sure your phone number is correct!")
  });

  const [email, setEmail] = useState(user.email || "");
  const [country, setCountry] = useState(user.profile?.address_country || "");

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
      <Logo />
      <p style={{ margin: 0 }}>
        We work together with Gigapay, our payment partner, they will manage your payment process
        all way.
      </p>
      <p>
        Any questions regarding your payment (invoice, banktransfer, etc.) will be handled by
        Gigapay only.
      </p>

      <p style={{ margin: 0 }}>
        You will get paid within 60 days <b>after</b> executed campaign.
      </p>

      <p>
        Click the button below to receive first an SMS and then a confirmation email invitation from
        Gigapay to setup your account and opt for the preferred payout method.
      </p>
      <p style={{ margin: 0 }}>
        NOTE - Please observe that the invitation only is valid for 14 days.
      </p>

      {user?.gigapayId && user?.gigapayStatus ? (
        <>
          <p style={{ color: "green" }}>You are already registered!</p>
          <Button
            isLoading={loading}
            onClick={() => navigate(pathname.replace("/payment", "/done"))}
          >
            Done!
          </Button>
        </>
      ) : (
        <>
          <InputsBox>
            <TextInput
              color="secondary"
              required
              style={{
                marginTop: 20,
                maxWidth: "100%",
                background: palette.gray.white
              }}
              value={email}
              label="Billing email"
              placeholder="Billing email"
              onChange={setEmail}
            />
            {!user.logisticsEnabled && (
              <CountrySelect value={country} onChange={v => setCountry(v || "")} />
            )}
          </InputsBox>
          <span>
            Please use the email address provided for payment, if you want your agency to manage it,
            please provide their email instead.
          </span>
          <Button
            disabled={!email || (!user.logisticsEnabled && !country)}
            isLoading={loading}
            onClick={() => gigaPayRegisterUser({ variables: { email, country } })}
          >
            Invite me!
          </Button>
        </>
      )}
    </div>
  );
};
